import router from '~/configs/routes';

import Apis from '~/pages/Apis';
import Home from '~/layouts/Home/Home';
import Cart from '~/pages/Billing/Cart';
import Docs from '~/pages/document/Docs';
import NotFound from '~/layouts/NotFound';
import Orders from '~/pages/Billing/Orders';
import ApiKey from '~/pages/account/ApiKey';
import Sources from '~/pages/source/Sources';
import ApisDetail from '~/pages/Apis/Detail';
import Balance from '~/pages/Billing/Balance';
import Profile from '~/pages/account/Profile';
import General from '~/pages/account/General';
import Recharge from '~/pages/Billing/Recharge';
import Security from '~/pages/account/Security';
import Notification from '~/pages/Notification';
import Invoices from '~/pages/Billing/Invoices';
import Templates from '~/pages/template/Templates';
import RefProfile from '~/components/RefProfile';
import BillingTemplate from '~/pages/Billing/Template';
import Login from '~/layouts/components/Account/Login';
import SourcesDetail from '~/pages/source/SourceDetail';
import HistoryLogin from '~/pages/account/HistoryLogin';
import Verify from '~/layouts/components/Account/Verify';
import TwoFactor from '~/pages/account/Security/TwoFactor';
import SourceCategory from '~/pages/source/SourceCategory';
import UserNotification from '~/pages/account/Notification';
import OrderDetail from '~/pages/Billing/Orders/OrderDetail';
import TemplateDetail from '~/pages/template/TemplateDetail';
import Register from '~/layouts/components/Account/Register';
import InvoiceDetail from '~/pages/Billing/Invoices/InvoiceDetail';
import ChangePassword from '~/pages/account/Security/ChangePassword';
import TemplateBillingDetail from '~/pages/Billing/Template/TemplateDetail';

export const privateRoutes = [
    { path: '*', component: NotFound },
    { path: router.home, component: Home },
    { path: router.docs, component: Docs },
    { path: router.cart, component: Cart },
    { path: router.services, component: Apis },
    { path: router.apikey, component: ApiKey },
    { path: router.user, component: RefProfile },
    { path: router.profile, component: Profile },
    { path: router.general, component: General },
    { path: router.sources, component: Sources },
    { path: router.billing, component: Recharge },
    { path: router.security, component: Security },
    { path: router.template, component: Templates },
    { path: router.twoFactor, component: TwoFactor },
    { path: router.billing_orders, component: Orders },
    { path: router.billing_balance, component: Balance },
    { path: router.login, component: Login, layout: null },
    { path: router.billing_invoices, component: Invoices },
    { path: router.notifications, component: Notification },
    { path: router.history_login, component: HistoryLogin },
    { path: router.verify, component: Verify, layout: null },
    { path: router.notification, component: UserNotification },
    { path: router.changePassword, component: ChangePassword },
    { path: router.services + '/:slug', component: ApisDetail },
    { path: router.register, component: Register, layout: null },
    { path: router.billing_template, component: BillingTemplate },
    { path: router.sources + '/:category', component: SourceCategory },
    { path: router.sources + '/detail/:slug', component: SourcesDetail },
    { path: router.template + '/detail/:slug', component: TemplateDetail },
    { path: router.billing_orders + '/:order_id', component: OrderDetail },
    { path: router.billing_invoices + '/:invoice_id', component: InvoiceDetail },
    { path: router.billing_template + '/:template_id', component: TemplateBillingDetail },
];
