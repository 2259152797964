import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useState } from 'react';
import { Avatar, notification, Table, Tabs, Tooltip } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconArrowNarrowRight, IconWorldWww } from '@tabler/icons-react';

import router from '~/configs/routes';
import { calculateDaysLeft } from '~/configs';
import { logoutUserSuccess } from '~/redux/reducer/auth';
import { requestUserGetServiceUsingOrderTemplate } from '~/services/using';

function ServiceUsing() {
    const [orderTemplates, setOrderTemplates] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        const fetch = async () => {
            const result = await requestUserGetServiceUsingOrderTemplate();

            if (result.status === 401 || result.status === 403) {
                dispatch(logoutUserSuccess());
                navigate(`${router.login}?redirect=${pathname}`);
            } else if (result?.status === 200) {
                setOrderTemplates(result.data);
            } else {
                notification.error({
                    message: 'Thông báo',
                    description: result?.error || 'Lỗi hệ thống vui lòng thử lại sau',
                });
            }
        };
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: 'Tên',
            key: 'title',
            render: (data) => (
                <Link className="text-current hover-blue font-bold" to={`${router.billing_template}/${data.id}`}>
                    {data.title.toUpperCase()}
                </Link>
            ),
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let className = '';
                let style = {};

                if (status === 'activated') {
                    className = 'label-light-success font-weight-bold';
                    style = { backgroundColor: '#4caf501a', color: '#4caf50', border: '1px solid #4caf501a' };
                }
                if (status === 'pending' || status === 'wait_confirm') {
                    className = 'label-light-warning font-weight-bold';
                    style = { backgroundColor: '#ff98001a', color: '#ff9800', border: '1px solid #ff98001a' };
                }
                if (status === 'inactivated' || status === 'expired' || status === 'blocked' || status === 'deleted') {
                    className = 'label-light-danger font-weight-bold';
                    style = { backgroundColor: '#f443361a', color: '#f44336', border: '1px solid #f443361a' };
                }

                return (
                    <div className={className} style={style}>
                        {status.toUpperCase()}
                    </div>
                );
            },
        },
        {
            title: 'Thời gian',
            key: 'date',
            render: (date) => (
                <Fragment>
                    <Tooltip title="Ngày đăng ký">
                        <span>{moment(date.created_at).format('DD/MM/YYYY HH:mm')}</span>
                    </Tooltip>
                    <br />
                    <Tooltip title="Ngày hết hạn">
                        <span>{moment(date.expired_at).format('DD/MM/YYYY HH:mm')}</span>
                    </Tooltip>
                    <br />
                    (Còn <b className={calculateDaysLeft(date.expired_at) < 8 ? 'text-danger' : ''}>
                        {calculateDaysLeft(date.expired_at)}
                    </b>{' '}
                    ngày)
                </Fragment>
            ),
        },
    ];

    const items = [
        {
            label: (
                <span className="box-align-center gap-2">
                    <Avatar
                        style={{ width: 20, height: 20, lineHeight: 20, fontSize: 18, background: '#096eff', padding: 1 }}
                        icon={<IconWorldWww />}
                    />
                    Website ({orderTemplates.length})
                </span>
            ),
            key: '1',
            children: (
                <Fragment>
                    <Table
                        columns={columns}
                        dataSource={orderTemplates.map((template) => ({ key: template.id, ...template }))}
                        pagination={false}
                    />

                    <div className="p-2 d-flex">
                        <Link className="hover-underline box-center hover-blue font-size-15" to={router.billing_template}>
                            <span className="mr-1">Xem thêm</span>
                            <IconArrowNarrowRight width={20} height={20} />
                        </Link>
                    </div>
                </Fragment>
            ),
        },
    ];

    return (
        <div className="mb-8">
            <h2 className="font-semibold font-size-20 mb-4">Các dịch vụ đang sử dụng</h2>

            <Tabs type="card" items={items} className="home_tabs" />
        </div>
    );
}

export default ServiceUsing;
